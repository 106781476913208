<template>
  <div class="app-content">
    <home></home>
  </div>
</template>

<script>
import home from '../views/compontents/home/index.vue';
export default {
  components: {
    home,
  },
  data() {
    return {
      myid: '',
    };
  },
  mounted() {
    // console.log('传参', $route.query.id);
    // this.myid = this.$route.query.id;
    // console.log(11111111, this.$route.query.id);
  },
  watch: {
    myid(newId) {},
  },
};
</script>

<style lang="scss" scoped>
.app-content {
  width: 100%;
}
</style>
